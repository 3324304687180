import React, { useState, useRef, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  Container,
  Stack,
} from "@mui/material";
import pic from "../Media/faqs.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function Faqs({ faqs }) {
  const matches = useMediaQuery("(min-width:450px)");
  const [expandedIndex, setExpandedIndex] = useState(null);
  const videoRefs = useRef([]);

  useEffect(() => {
    setTimeout(() => {
      videoRefs.current.forEach((video, index) => {
        if (video) {
          if (index === expandedIndex) {
            video.play().catch((error) => console.log(error));
          } else {
            video.pause();
            video.load()
          }
        }
      });
    }, 0);
  }, [expandedIndex]);

  const handleAccordionChange = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div
      style={{
        marginTop: "90px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
      id="FAQs"
    >
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          mb: matches ? "" : "20px",
        }}
      >
        <Typography
          sx={{ color: "#7B3AE2", fontSize: "30px", fontWeight: "bold" }}
        >
          FAQs
        </Typography>
      </Container>
      <Container>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack gap={1}>
            {faqs.map((q, index) => (
              <Accordion
                key={index}
                elevation={0}
                expanded={expandedIndex === index}
                onChange={() => handleAccordionChange(index)}
                classes={{ root: "accordion-root" }}
              >
                <AccordionSummary
                  expandIcon={
                    expandedIndex === index ? (
                      <RemoveIcon sx={{ color: "white" }} />
                    ) : (
                      <AddIcon sx={{ color: "white" }} />
                    )
                  }
                  sx={{
                    bgcolor: "#6CD6A9",
                    borderRadius: "10px",
                    "&:before": { display: "none" },
                    padding: "20px",
                    width: matches ? "500px" : "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    {q.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    borderBottom: "1px solid #7B3AE2",
                    borderRight: "1px solid #7B3AE2",
                    borderLeft: "1px solid #7B3AE2",
                    borderRadius: "10px",
                    marginTop: "-1px",
                  }}
                >
                  <Typography sx={{ color: "#7D4896", fontSize: "15px" }}>
                    <Container maxWidth="xs">{q.answer}</Container>
                  </Typography>
                  {q.attachment_mime_type === "video/mp4" && (
                    <video
                      ref={(el) => (videoRefs.current[index] = el)}
                      src={`${process.env.REACT_APP_API_URL_IMAGE}${q.attachment}`}
                      controls
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        height: "200px",
                      }}
                    />
                  )}
                  {q.attachment_mime_type === "image/png" && (
                    <img
                      src={`${process.env.REACT_APP_API_URL_IMAGE}${q.attachment}`}
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        height: "200px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
          {matches && (
            <img
              src={pic}
              style={{
                width: matches ? "500px" : "300px",
                height: matches ? "500px" : "300px",
              }}
            />
          )}
        </Stack>
      </Container>
    </div>
  );
}
