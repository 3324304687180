import React, { useEffect, useState } from 'react'
import pic from '../Media/terms.png'
import { Box, Container, Typography, useMediaQuery } from '@mui/material'
import axios from 'axios';
import LoadingPage from '../Component/LoadingPage';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function Terms() {
  const [open, setOpen] = useState(true);
  const [terms, setTerms] = useState('')
  var head = 'zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas'
  const matches = useMediaQuery('(min-width:800px)');

  useEffect(()=>{
    window.scrollTo(0,0)
    axios.get(`${process.env.REACT_APP_API_URL}terms`, {
      headers: {
        'X-Api-Password': head
      }
    }).then(res=>{
      setTerms(res.data.data.terms)
      setOpen(false)
    })
},[])
  return (
    <div style={{position:'relative'}}>
      <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'
      ,alignItems:'center',gap:'10px',position:'fixed',zIndex:10,
     top:matches?'85%':'89%',right:matches?'10px':'12px'}}>
    
    <a href={`${localStorage.getItem('whats')}`} target="_blank">
      <WhatsAppIcon sx={{color:'#32B929',fontSize:matches?'60px':'40px',cursor:'pointer'}}/>
      </a>
      </div>
      <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',mt:'110px',mb:'30px'}}>
        <img src={pic} style={{height:'300px'}}/>
      </Box>
      <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',mb:'60px'}}>
      <Typography sx={{color:'#7B3AE2',fontWeight:'bold',fontSize:'25px'}}>
      Terms & Conditions
      </Typography>
      </Box>

      {open && <LoadingPage open={open} />}
      {!open &&
      <Container maxWidth='lg' >
        <Typography
          sx={{ fontSize: '20px', color: '#888888' ,mb:'100px'}}
          dangerouslySetInnerHTML={{ __html: terms }}
        ></Typography>
        </Container>}
    </div>
  )
}
