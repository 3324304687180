import React, { useEffect, useState } from 'react'
import Intro from '../Component/Intro'
import Services from '../Component/Services'
import Features from '../Component/Features'
import Reviews from '../Component/Reviews'
import Video from '../Component/Video'
import Faqs from '../Component/Faqs'
import BookDemo from '../Component/BookDemo'
import Packages from '../Component/Packages'
import { Button, Stack, useMediaQuery } from '@mui/material'
import ContactInfo from '../Component/ContactInfo'
import Form from '../Component/Form'
import bg from '../Media/form.png'
import axios from 'axios'
import LoadingPage from '../Component/LoadingPage'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom'

export default function MainPage() {
  const [banners,setBanners]=useState([])
  const [service,setService]=useState({})
  const [features,setFeatures]=useState([])
  const [review,setReview]=useState([])
  const [faqs,setFaqs]=useState([])
  const [contact, setContact] = useState({});
  const [packages, setPackages] = useState([]);
  const [open, setOpen] = useState(true);
  var head = 'zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas'
  const matches = useMediaQuery('(min-width:800px)');
  const match = useMediaQuery('(min-width:500px)');

  useEffect(()=>{
    window.scrollTo(0,0)
    axios.get(`${process.env.REACT_APP_API_URL}home`, {
      headers: {
        'X-Api-Password': head
      }
    }).then(res=>{
      setBanners(res.data.data.banners)
      setService(res.data.data.service)
      setFeatures(res.data.data.features)
      setReview(res.data.data.testimonials)
      setFaqs(res.data.data.faqs)
      setFaqs(res.data.data.faqs)
      setContact(res.data.data.general)
      setPackages(res.data.data.packeges)
      setOpen(false)
      localStorage.setItem('whats',res.data.data.general.whatsapp)
      localStorage.removeItem('name')
      localStorage.removeItem('val')
      localStorage.removeItem('id')
      localStorage.removeItem('role')
      localStorage.removeItem('logo')
    })
},[])
  return (
    <div style={{position:'relative'}}>
      {open && <LoadingPage open={open} />}
      {!open &&<>
      <Intro banners={banners} />
      <Services service={service} />
      <Features features={features} />
      <Video contact={contact}/>
      <Faqs faqs={faqs} />
      <Reviews review={review} />
      <BookDemo />
      {/* <Packages packages={packages} /> */}
      <Stack sx={{position:'relative'}} id='Contact Us'>
{matches&&      <img src={bg} style={{position:'absolute',left:'0%',height:'700px',top:'-20px',objectFit:'cover',width:match?'':'100%'}}/>
}
        <Stack direction={matches ? 'row' : 'column'} 
         sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',gap:'10%'}}>
      <Form />
      <ContactInfo contact={contact} />
      </Stack>
      </Stack> </>}
      <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'
      ,alignItems:'center',gap:'10px',position:'fixed',zIndex:10,
     top:matches?'85%':'89%',right:matches?'10px':'12px'}}>
    
    <a href={`${contact.whatsapp}`} target="_blank">
      <WhatsAppIcon sx={{color:'#32B929',fontSize:matches?'60px':'40px',cursor:'pointer'}}/>
      </a>
      </div>
    </div>
  )
}
