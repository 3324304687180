import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/material/Rating';
import { Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import axios from 'axios';



const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" sx={{fontSize:'30px'}}/>,
    label: 1,
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" sx={{fontSize:'30px'}}/>,
    label: 2,
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" sx={{fontSize:'30px'}}/>,
    label: 3,
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" sx={{fontSize:'30px'}}/>,
    label: 4,
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" sx={{fontSize:'30px'}}/>,
    label: 5,
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}


export default function Survey({open,id}) {
    const [value, setValue] = React.useState(0);
    const [pvalue, setPValue] = React.useState(0);
    const [show, setShow] = React.useState(true);
    const [msg, setMsg] = React.useState('');
    const [pmsg, setPMsg] = React.useState('');
    const navigate =useNavigate()
    const matches = useMediaQuery('(min-width:500px)');

    const handleDone =() =>{
      if(value && msg && pvalue && pmsg){
      navigate('/')
      const formData = new FormData();
      formData.append("candidate_id", id);
      formData.append("questions_rate", value);
      formData.append("questions_feedback", msg);
      formData.append("process_rate", pvalue);
      formData.append("process_feedback", pmsg);
      var head = 'zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas'
      axios.post(`${process.env.REACT_APP_API_URL}rate`,formData, {
        headers: {
          'X-Api-Password': head
        }
      })
    }
    else{alert('All fields must be filled.')}
    }

    const handleNext=() =>{
      if(value && msg){
        setShow(false)
      }
      else{
        alert('All fields must be filled.')
      }
    }
  return (
    <div>
       <Dialog
        open={open}
        maxWidth='xl'
      >
        {show && <><DialogTitle sx={{justifyContent:'center',alignItems:'center',display:'flex',mb:'0px'}}>
        Please rate the questions</DialogTitle>
        <DialogContent sx={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
        <StyledRating
      IconContainerComponent={IconContainer}
      highlightSelectedOnly
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
       />

        {/* <Rating
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      /> */}
        {/* <TextField
              placeholder="write a message"
              value={msg}
              onChange={(e) => { setMsg(e.target.value) }}
              InputLabelProps={{
                sx: {
                  color: '#7B3AE2'
                }
              }}
              sx={{borderRadius:'5px', width:'250px', border: '1px solid white', 
              bgcolor:'white',mt:'20px', overflow: 'auto',
              '&:focus': { border: '1px solid white' },'.MuiInputBase-input':{color:"#7B3AE2"} ,
              "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                color: "#7B3AE2",opacity:1
              }}}
            /> */}

<textarea
        onChange={(e) => { setMsg(e.target.value) }}
        name="message"
        placeholder='Message'
        value={msg}
        className="custom-textarea"
        style={{ resize: 'none', overflow: 'auto', height:'100px',width:matches?'400px':'200px' , outline:'none',marginTop:'20px'}}
        />
       <Button sx={{
                    color: 'white',
                    bgcolor: '#0D969B',
                    '&:hover': { color: 'white', bgcolor: '#0D969B' },
                    padding: '5px 20px 5px 20px',
                    borderRadius:'20px',mt:'20px'
                  }} onClick={handleNext}>
                Next
                  </Button>
        </DialogContent></>}

        {!show && <><DialogTitle sx={{justifyContent:'center',alignItems:'center',display:'flex',mb:'0px'}}>
        Please rate the proccess </DialogTitle>
        <DialogContent sx={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
        {/* <Rating
        value={pvalue}
        onChange={(event, newValue) => {
            setPValue(newValue);
        }}
      /> */}
  <StyledRating
      IconContainerComponent={IconContainer}
      getLabelText={(value) => customIcons[value].label}
      highlightSelectedOnly
      value={pvalue}
      onChange={(event, newValue) => {
        setPValue(newValue);
      }}
    />
<textarea
        onChange={(e) => { setPMsg(e.target.value) }}
        name="message"
        placeholder='Message'
        value={pmsg}
        className="custom-textarea"
        style={{ resize: 'none', overflow: 'auto', height:'100px',width:matches?'400px':'200px' , outline:'none',marginTop:'20px'}}
        />
       <Button sx={{
                    color: 'white',
                    bgcolor: '#0D969B',
                    '&:hover': { color: 'white', bgcolor: '#0D969B' },
                    padding: '5px 20px 5px 20px',
                    borderRadius:'20px',mt:'20px'
                  }} onClick={()=>{handleDone()}}>
                Done
                  </Button>
        </DialogContent></>}
      </Dialog>
    </div>
  )
}
