import React from 'react'
import { Container, Typography, useMediaQuery } from '@mui/material'

export default function Video({contact}) {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <div style={{marginTop:'90px'}}>
       <Container sx={{ display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
      <Typography  sx={{color:'#7B3AE2',fontSize:'30px',fontWeight:'bold',textAlign:matches?'start':'center'}}>
      So Simple. So Efficient.
      </Typography>
      </Container>
        <div style={{position:'relative',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}> 
       <video src={`${process.env.REACT_APP_API_URL_IMAGE}${contact.video}`} style={{width:matches?'70%':'80%',borderRadius:matches?'50px':'',marginTop:'50px',marginBottom:matches?'':'70px'}}
       autoPlay loop muted></video>
    </div>
    </div>
  )
}
