import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import pic from "../Media/interviewPage.png";
import axios from "axios";
import LoadingPage from "../Component/LoadingPage";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function WelcomePage() {
  const windowHeight = useRef(window.innerHeight);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(true);
  const [lang, setLang] = useState(["Arabic", "English"]);
  const [selectedLang, setSelectedLang] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [stopRec, setStopRec] = useState(false);
  const navigate = useNavigate();
  const [mediaStream, setMediaStream] = useState(null);
  const matches = useMediaQuery("(min-width:500px)");
  const [check, setCheck] = useState(false);

  var head = "zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas";
  const canvasRef = useRef(null);
  const [analyser, setAnalyser] = useState(null);
  const videoRef = useRef(null);
  const currentPageUrl = window.location.href;
  var desiredUrl;
  const hashIndex = currentPageUrl.indexOf("#");
  if (hashIndex !== -1) {
    const hashFragment = currentPageUrl.substring(hashIndex + 1);
    const queryIndex = hashFragment.indexOf("?");
    if (queryIndex !== -1) {
      const queryParameters = new URLSearchParams(
        hashFragment.substring(queryIndex + 1)
      );
      const candidateId = queryParameters.get("candidate_id");
      const token = queryParameters.get("token");
      desiredUrl = `?link=https://swifthire.ai/%23/welcome?candidate_id=${candidateId}%26token=${token}`;
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    if (desiredUrl) {
      axios
        .get(`${process.env.REACT_APP_API_URL}interview-info${desiredUrl}`, {
          headers: {
            "X-Api-Password": head,
          },
        })
        .then((res) => {
          setData(res.data.data);
          setOpen(false);
          localStorage.setItem("name", res.data.data.candidate.name);
          localStorage.setItem("role", res.data.data.questions.title);
          localStorage.setItem("logo", res.data.data.company.logo);
          localStorage.setItem("co_name", res.data.data.company.name);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            alert("Invalid Link");
            navigate("/");
          } else {
            console.error(error);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (openDialog === true) {
      const getMedia = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
          });
          setMediaStream(stream);
          const audioContext = new AudioContext();
          const source = audioContext.createMediaStreamSource(stream);
          const analyserNode = audioContext.createAnalyser();
          analyserNode.fftSize = 2048;
          source.connect(analyserNode);
          setAnalyser(analyserNode);
        } catch (error) {
          console.error("Error accessing camera:", error);
          setOpenDialog(false);
        }
      };

      const permissionGranted = window.confirm("Allow access to the camera?");
      if (permissionGranted) {
        getMedia();
      } else {
        setOpenDialog(false);
      }

      return () => {
        if (mediaStream) {
          mediaStream.getTracks().forEach((track) => track.stop());
        }
      };
    }
  }, [openDialog]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = mediaStream;
    }
  }, [mediaStream]);

  useEffect(() => {
    if (!analyser || !canvasRef.current) return;
    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext("2d");
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const drawWave = () => {
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;

      analyser.getByteTimeDomainData(dataArray);

      canvasContext.fillStyle = "rgb(255, 255, 255)";
      canvasContext.fillRect(0, 0, WIDTH, HEIGHT);

      canvasContext.lineWidth = 2;
      canvasContext.strokeStyle = "#0D969B";

      canvasContext.beginPath();

      const sliceWidth = (WIDTH * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * HEIGHT) / 2;

        if (i === 0) {
          canvasContext.moveTo(x, y);
        } else {
          canvasContext.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasContext.lineTo(canvas.width, canvas.height / 2);
      canvasContext.stroke();

      requestAnimationFrame(drawWave);
    };

    drawWave();
  }, [analyser]);

  return (
    <div
      style={{
        minHeight: windowHeight.current,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "start",
        backgroundColor: "#F1F3F6",
      }}
    >
      {open && <LoadingPage open={open} />}
      {!open && (
        <Box
          sx={{
            position: "relative",
            width: "80%",
            borderRadius: "20px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            minHeight: "500px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "white",
            marginTop: "130px",
            mb: "50px",
          }}
        >
          {matches && (
            <img
              src={pic}
              style={{ position: "absolute", top: "0px", right: "0px" }}
            />
          )}
          <Stack
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
            gap={2}
          >
            <img
              src={data.company.logo}
              style={{
                height: matches ? "250px" : "200px",
                width: matches ? "250px" : "200px",
                objectFit: "contain",
                marginTop: "20px",
              }}
            />
            <Container sx={{ width: matches ? "80%" : "100%" }}>
              <Typography
                sx={{
                  color: "#777777",
                  fontSize: "15px",
                  mt: "20px",
                  mb: "20px",
                  p: "12px",
                }}
              >
                Welcome <strong>{data.candidate.name}</strong>, <br />
                Thank you for your interest in the{" "}
                <strong>{data.questions.title}</strong> position at{" "}
                <strong>{data.company.name}</strong>
                . We're excited to learn more about you through this AI-powered
                interview process.
                <br />
                <br />
                Important Information:
                <br />• The interview will take approximately{" "}
                <strong>{data.questions.total_duration}</strong> minutes.
                <br />
                • Once started, the interview cannot be paused or resumed later.
                <br />
                • Please ensure you have a stable internet connection and are in
                a quiet environment.
                <br />
                <br />
                Before we begin:
                <br />
                1. Click the "Test Camera & Mic" button to ensure your equipment
                is working properly.
                <br />
                2. Once your camera and microphone are confirmed to be
                functioning, you'll be able to start the interview.
                <br />
                <br />
                When you're ready to proceed, click "Test Camera & Mic" to begin
                the setup process.
                <br />
                <br />
                We wish you the best of luck!
                <br />
                <strong>{data.company.name}</strong> Hiring Team
              </Typography>
            </Container>

            <Container
              sx={{
                width: matches ? "80%" : "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Checkbox
                onChange={() => {
                  setCheck(!check);
                }}
                sx={{
                  color: "#0D969B",
                  "&.Mui-checked": {
                    color: check ? "#0D969B" : "#000000",
                  },
                  fontSize: "15px",
                }}
              />
              <p style={{ color: "#777777", fontSize: "15px" }}>
                I have read and agree on all {""}
                <a
                  onClick={() => {
                    navigate("/terms");
                  }}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  Terms & Conditions
                </a>
              </p>
            </Container>

            {data.questions.multi_language === true && (
              <>
                <Container
                  maxWidth="sm"
                  sx={{
                    justifyContent: "start",
                    alignItems: "start",
                    textAlign: "start",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#777777",
                      fontSize: "15px",
                      mt: "20px",
                      mb: "10px",
                    }}
                  >
                    Select Language
                  </Typography>
                  <TextField
                    select
                    value={selectedLang}
                    onChange={(e) => {
                      setSelectedLang(e.target.value);
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "white",
                      },
                    }}
                    sx={{
                      borderRadius: "5px",
                      width: "210px",
                      border: "1px solid white",
                      "&:hover": { border: "1px solid white" },
                      mb: "20px",
                      ".MuiInputBase-input": { color: "black" },
                    }}
                  >
                    {lang.map((s) => (
                      <MenuItem value={s}>{s}</MenuItem>
                    ))}
                  </TextField>
                </Container>
              </>
            )}
            <Button
              sx={{
                color: "white",
                bgcolor: "#0D969B",
                "&:hover": { color: "white", bgcolor: "#0D969B" },
                padding: "5px 30px 5px 30px",
                borderRadius: "20px",
                marginBottom: "20px",
                mt: "10px",
              }}
              onClick={() => {
                if (check) {
                  setOpenDialog(true);
                } else {
                  alert("Please agree to the Terms & Conditions");
                }
              }}
            >
              Test Camera & Mic
            </Button>
          </Stack>
        </Box>
      )}

      {openDialog && (
        <Dialog maxWidth="sm" open={openDialog}>
          <DialogTitle
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              mb: "10px",
            }}
          >
            {" "}
            Test your Camera & Mic{" "}
          </DialogTitle>
          <DialogContent>
            {/* <Recording setOpenDialog={setOpenDialog} stopRec={stopRec} setMediaStream={setMediaStream} mediaStream={mediaStream}/> */}
            <div>
              <video
                ref={videoRef}
                style={{ width: "100%", height: "250px" }}
                autoPlay
              />
              <canvas
                ref={canvasRef}
                width={500}
                height={60}
                style={{ width: "100%", marginTop: "10px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack>
                <Typography sx={{ color: "red" }}>
                  {" "}
                  Important: Your response recording will begin automatically
                  after each question is fully presented.
                </Typography>
                {data.questions.multi_language === true ? (
                  <Button
                    sx={{
                      color: "white",
                      bgcolor: data.company.primary_color
                        ? `${data.company.primary_color}`
                        : "#0D969B",
                      "&:hover": { color: "white", bgcolor: "#0D969B" },
                      padding: "5px 30px 5px 30px",
                      borderRadius: "20px",
                      mt: "20px",
                    }}
                    onClick={() => {
                      if (selectedLang && check) {
                        navigate("/questions", {
                          state: {
                            data: data.candidate.id,
                            lang: selectedLang,
                          },
                        });
                        setStopRec(true);
                      } else if (!selectedLang) {
                        alert("Please Select Language");
                        setOpenDialog(false);
                      }
                      localStorage.setItem("val", 0);
                      if (mediaStream) {
                        mediaStream
                          .getTracks()
                          .forEach((track) => track.stop());
                      }
                      setStopRec(true);
                    }}
                  >
                    Begin Interview
                  </Button>
                ) : (
                  <Button
                    sx={{
                      color: "white",
                      bgcolor: data.company.primary_color
                        ? `${data.company.primary_color}`
                        : "#0D969B",
                      "&:hover": { color: "white", bgcolor: "#0D969B" },
                      padding: "5px 30px 5px 30px",
                      borderRadius: "20px",
                      mt: "20px",
                    }}
                    onClick={() => {
                      localStorage.setItem("val", 0);
                      navigate("/questions", {
                        state: { data: data.candidate.id, lang: "en" },
                      });
                      setStopRec(true);
                      if (mediaStream) {
                        mediaStream.getTracks().forEach((track) => {
                          track.stop();
                        });
                      }
                    }}
                  >
                    Begin Interview
                  </Button>
                )}
              </Stack>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
