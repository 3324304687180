import { Button, Container, Stack, Typography, useMediaQuery } from '@mui/material'
import React,{useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay,Navigation } from 'swiper/modules'; 
import '../App.css'
import 'swiper/css/navigation';
import pic from '../Media/Icons.png'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FeaturesDialog from './FeaturesDialog';

export default function Features({features}) {
    const matches = useMediaQuery('(min-width:1000px)');
    const [open, setOpen] = useState(false);
    const [data, setData] = useState('');
  
    const handleReadMore = (selectedData) => {
      setOpen(true);
      setData(selectedData);
    };

  return (
    <div style={{marginTop:matches?'':'110px',position:'relative'}} id='Features'>
        <Container sx={{ display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
      <Typography  sx={{color:'#7B3AE2',fontSize:'30px',fontWeight:'bold',textAlign:'center'}}>
      Why Is Swift Hire Needed
      </Typography>
      </Container>
<Container maxWidth='md' >
      <Swiper
      initialSlide={1}
      modules={[Autoplay,Navigation]} 
      loop={true}
      autoplay={{
       delay: 5000,
       disableOnInteraction: false,
        }}
        navigation={{
            prevEl: '.custom-swiper-prev',
            nextEl: '.custom-swiper-next',
          }}
        slidesPerView={matches ? 3 : 1}
        spaceBetween={30}
        style={{paddingBottom:'50px',paddingTop:'55px'}}
      >
        {features.map((f)=>
        <SwiperSlide style={{   display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
        }}>
        <div class="card-container">
            <Stack gap={1} sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
        <img  src={`${process.env.REACT_APP_API_URL_IMAGE}${f.image}`} alt="...loading" style={{width:'80px',height:'80px'}}/><br/>
        <Typography sx={{color:'#7B3AE2',fontSize:'15px'}}>
        {f.title}
        </Typography>
	<p>{`${f.description.substring(0, 150)} `} 
  {f.description.length > 150 && (
                    <Button sx={{color:'#6CD6A9','&:hover':{color:'#6CD6A9'}}}
                    onClick={() => handleReadMore({ profile_photo_url: `${process.env.REACT_APP_API_URL_IMAGE}${f.image}`, title: f.title, text:f.description})}
                    >
                      Read More
                    </Button>
                  )}
   </p>
    </Stack>
</div>
        </SwiperSlide>)}
        </Swiper>
        </Container>


        {matches && <><div className="custom-swiper-prev">
        <ArrowLeftIcon
          style={{
            color: '#7B3AE2',
            fontSize: '70px',
            position: 'absolute',
            top: '50%',
            left: '40px',
            zIndex: '1',
            cursor: 'pointer',
          }}
        />
      </div>
      <div className="custom-swiper-next">
        <ArrowRightIcon
          style={{
            color: '#7B3AE2',
            fontSize: '70px',
            position: 'absolute',
            top: '50%',
            right: '40px',
            zIndex: '1',
            cursor: 'pointer',
          }}
        />
      </div></>}
      <FeaturesDialog open={open} setOpen={setOpen} data={data} />
    </div>
  )
}
