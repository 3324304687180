import { Box, useMediaQuery, Link, Stack, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function ContactInfo({contact}) {
  const[about,setAbout]=useState({})
  const matches = useMediaQuery('(min-width:960px)');
 
  const handleMailClick = () => {
    window.open(`mailto:${contact.email}`);
  };
  return (
    <div id='contact'>
      <Stack sx={{display:'flex',flexWrap:'wrap',justifyContent:'start',alignItems:'start',marginTop:matches?'90px':'10px'}} gap={3}>
        {contact.phone_number ?
        <Link href={`tel:${contact.phone_number}`} style={{textDecoration: 'none'}}>
          <Stack direction='row' gap={2} sx={{cursor:'pointer'}}> 
          <PhoneIcon sx={{bgcolor:'#6CD6A9' , color:'white' , borderRadius:'30px',padding:'10px'}}/>
        <Stack sx={{display:'flex',flexWrap:'wrap',justifyContent:'start',alignItems:'start'}}>
        <Typography sx={{fontSize:'20px',fontWeight:'bold',color:'#7B3AE2',textDecoration: 'none'}} gap={2}>
          Phone Number
        </Typography>
        <Typography sx={{fontSize:'12px',color:'#747474',textDecoration: 'none'}}>
          {contact.phone_number}
        </Typography>
        </Stack>
        </Stack>
        </Link> : null}


        <Stack direction='row' gap={2} onClick={handleMailClick} sx={{cursor:'pointer'}}> 
        {contact.email ?<>
        <a>
        <MailOutlineIcon sx={{bgcolor:'#6CD6A9' , color:'white' , borderRadius:'30px',padding:'10px'}}/>
        </a>
        <Stack sx={{display:'flex',flexWrap:'wrap',justifyContent:'start',alignItems:'start'}}>
        <Typography sx={{fontSize:'20px',fontWeight:'bold',color:'#7B3AE2'}}>
          Email
        </Typography>
        <Typography sx={{fontSize:'12px',color:'#747474'}}>
        {contact.email}
        </Typography>
        </Stack> </> : null}
        </Stack>

        {contact.location ?
        <Stack direction='row' gap={2} sx={{cursor:'pointer'}}> 
        <LocationOnIcon sx={{bgcolor:'#6CD6A9' , color:'white' , borderRadius:'30px',padding:'10px'}}/>
        <Stack sx={{display:'flex',flexWrap:'wrap',justifyContent:'start',alignItems:'start'}}>
        <Typography sx={{fontSize:'20px',fontWeight:'bold',color:'#7B3AE2'}}>
         Location
        </Typography>
        <Typography sx={{fontSize:'12px',color:'#747474'}}>
        {contact.location}
        </Typography>
        </Stack>
        </Stack>
 : null} 

        <Box sx={{justifyContent:'end',alignItems:'end',display:'flex',flexWrap:'wrap',marginBottom:matches?'':'50px'}}>
         <Stack direction='row' gap={2}> 
       {contact.facebook ?
        <Link href={contact.facebook} target="_blank">
        <FacebookIcon sx={{bgcolor:"transparent" , color:'#7B3AE2' , borderRadius:'30px'
        ,padding:'10px', cursor:'pointer'}}/>
        </Link> : null}


        {contact.instagram ?<Link href={contact.instagram} target="_blank">
        <InstagramIcon sx={{bgcolor:"transparent" , color:'#7B3AE2' , borderRadius:'30px'
        ,padding:'10px', cursor:'pointer'}}/>
        </Link> :null }


        {contact.linkedin ?
         <Link href={contact.linkedin} target="_blank">
        <LinkedInIcon sx={{bgcolor:"transparent" , color:'#7B3AE2' ,
        borderRadius:'30px',padding:'10px', cursor:'pointer'}}/>
        </Link>
       : null
      } 
        </Stack>
        </Box>
   
      </Stack>
    </div>
  )
}
