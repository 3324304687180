import React,{useEffect, useState} from 'react'
import vid from '../Media/video.mp4'
import { Box, Container } from '@mui/material'
import {  TextField, Grid, Stack, useMediaQuery, Button, Alert } from '@mui/material';
import PhoneInput from "react-phone-input-2";
import '../App.css';
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';

export default function BookDemo() {
    const [firstName, setFirstName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [company_name, setcompany_name] = useState('');
    const matches = useMediaQuery('(min-width:879px)');
    const match = useMediaQuery('(min-width:600px)');

    const handleSend=() =>{
      const formData = new FormData();
      if(firstName && company_name && phone && email ){
      formData.append('name', firstName );
      formData.append('phone_number', phone);
      formData.append('company_name', company_name);
      formData.append('email', email);
      formData.append('type', 'book');
      var head = 'zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas'

      try{
      axios.post(`${process.env.REACT_APP_API_URL}forms`,formData, { headers: {
        'X-Api-Password':head ,
        }}).then(
        res=>{ 
          if(res.data.status === true){
            alert('Message Send Successfully')
            setFirstName('')
            setcompany_name('')
            setPhone('')
            setEmail('')
          }
        }
      )}
      catch(error){
        console.log(error)
      }
      }
      else{
        alert('All fields must be filled.')
      }
    }
  return (
    <div style={{marginTop:'70px',position:'relative'}} id='Book A Demo'>
      <video src={vid} style={{width:'100%', height:match?'570px':'750px', objectFit:'cover'}} autoPlay loop muted>  
        </video>

        <Box sx={{bgcolor: 'rgba(123, 58, 226, 0.9)',
        width:match?'60%':'70%',position:'absolute',
        top:matches?'14%':'10%',left:match?'20%':'10%',
        padding:'30px 20px 20px 20px',borderRadius:'10px',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
        <Container sx={{textAlign:'center',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',color:'#6CD6A9',fontSize:'30px',fontWeight:'bold'}}>
        Book A Demo!
        </Container>
        {/* <Container maxWidth='sm' sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',color:'white',mt:'20px'}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        </Container> */}

        <Stack 
        direction={matches?'row':'column'} gap={3}
         sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',mt:'30px'}}>
          
        <TextField
              placeholder="Name"
              value={firstName}
              onChange={(e) => { setFirstName(e.target.value) }}
              sx={{borderRadius:'5px',  width: matches?'300px':'225px', border: '1px solid white', 
              '&:hover': { border: '1px solid white' },'.MuiInputBase-input':{color:"#7B3AE2"},
              "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                color: "gray",opacity:1
              },bgcolor:'white'}}
            />

          

        <TextField
              placeholder="Company Name"
              value={company_name}
              onChange={(e) => { setcompany_name(e.target.value) }}
              sx={{borderRadius:'5px',  width: matches?'300px':'225px', border: '1px solid white', 
              '&:hover': { border: '1px solid white' },'.MuiInputBase-input':{color:"#7B3AE2"},
              "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                color: "gray",opacity:1
              },bgcolor:'white'}}
            />
          </Stack>
          
          <Stack direction={matches?'row':'column'} gap={3} sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',mt:'20px'}}>
            <Grid>
          <PhoneInput
        country={"sa"}
        enableSearch={true}
        value={phone}
        onChange={setPhone}
        inputStyle={{
          backgroundColor: 'white',
          width: matches?'300px':'225px',
          color: '#7B3AE2',
          fontSize: '13px',
          border: '0.1px solid rgba(196, 196, 196, 0.582)',
          borderRadius: '5px',
          height: '55px',
        }}
      /></Grid>
      <Grid>
        <TextField
              placeholder="Email"
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
              sx={{borderRadius:'5px',  width: matches?'300px':'225px', border: '1px solid white', 
              '&:hover': { border: '1px solid white' },'.MuiInputBase-input':{color:"#7B3AE2"},
              "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                color: "gray",opacity:1
              },bgcolor:'white'}}
            />
          </Grid>
          </Stack>

          <Grid sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',mt:'30px'}}>
          <Button  sx={{color:'white',bgcolor:'#6CD6A9','&:hover':{color:'white',bgcolor:'#6CD6A9'}
           ,width:matches?'420px':'225px',mb:'20px'}} onClick={handleSend}>BOOK A DEMO</Button>
            </Grid>
       </Box>
    </div>
  )
}
