import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Container, Rating } from "@mui/material";
import q1 from "../Media/qoute.png";
import q2 from "../Media/qoute2.png";

export default function ReviewsDialog({ open, setOpen, data }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      sx={{ ".MuiPaper-root": { bgcolor: "transparent", boxShadow: "none" } }}
    >
      <DialogContent>
        <div className="rev-containerrr">
          <img
            src={q1}
            style={{
              position: "absolute",
              top: "5px",
              left: "1px",
              width: "70px",
            }}
          />
          <img
            src={q2}
            style={{
              position: "absolute",
              bottom: "5px",
              right: "1px",
              width: "70px",
            }}
          />
          <img
            src={data.profile_photo_url}
            alt="loading"
            style={{ borderRadius: "100%", width: "120px", height: "120px" }}
            referrerPolicy="no-referrer"
          />
          <br />
          <p style={{ color: "white" }}>{data.text}</p>
          <p style={{ color: "#7B3AE2", fontWeight: "normal" }}>
            {data.name} <br /> {data.cname}
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}
