import {
  Typography,
  TextField,
  Grid,
  Stack,
  TextareaAutosize,
  useMediaQuery,
  Button,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "../App.css";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

export default function Form() {
  const [firstName, setFirstName] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setemail] = useState("");
  const [alerting, setAlerting] = React.useState(false);
  const [message, setMessage] = useState("");

  const matches = useMediaQuery("(min-width:879px)");

  const handlesend = () => {
    const formData = new FormData();
    if (firstName && company_name && phone && email && message) {
      formData.append("name", firstName);
      formData.append("phone_number", phone);
      formData.append("company_name", company_name);
      formData.append("email", email);
      formData.append("message", message);
      formData.append("type", "feed");
      var head = "zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas";

      try {
        axios
          .post(`${process.env.REACT_APP_API_URL}forms`, formData, {
            headers: {
              "X-Api-Password": head,
            },
          })
          .then((res) => {
            if (res.data.status === true) {
              alert("Message Send Successfully");
              setFirstName("");
              setcompany_name("");
              setPhone("");
              setMessage("");
              setemail("");
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setAlerting(true);
    }
  };

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: "rgba(196, 196, 196, 0.582)",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(196, 196, 196, 0.582)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(196, 196, 196, 0.582)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(196, 196, 196, 0.582)",
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              "&::before, &::after": {
                borderBottomColor: "rgba(196, 196, 196, 0.582)",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "rgba(196, 196, 196, 0.582)",
              },
              "&.Mui-focused:after": {
                borderBottomColor: "rgba(196, 196, 196, 0.582)",
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              "&::before": {
                borderBottomColor: "rgba(196, 196, 196, 0.582)",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "rgba(196, 196, 196, 0.582)",
              },
              "&.Mui-focused:after": {
                borderBottomColor: "rgba(196, 196, 196, 0.582)",
              },
            },
          },
        },
      },
    });

  const outerTheme = useTheme();

  return (
    <div
      style={{
        marginTop: matches ? "70px" : "10px",
        position: "relative",
        marginBottom: "50px",
      }}
    >
      <Typography
        sx={{
          color: "#7D4896",
          fontSize: "30px",
          fontWeight: "bold",
          marginBottom: "40px",
          textAlign: matches ? "start" : "center",
        }}
      >
        Got more questions?
        <br />
        Drop us a message!
      </Typography>
      <ThemeProvider theme={customTheme(outerTheme)}>
        <Stack direction={matches ? "row" : "column"} gap={2}>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              placeholder="Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              InputLabelProps={{
                sx: {
                  color: "#7B3AE2",
                },
              }}
              sx={{
                borderRadius: "5px",
                width: matches ? "350px" : "280px",
                border: "1px solid white",
                bgcolor: "white",
                "&:focus": { border: "1px solid white" },
                ".MuiInputBase-input": { color: "#7B3AE2" },
                "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                  color: "#7B3AE2",
                  opacity: 1,
                },
              }}
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              placeholder="Company Name"
              value={company_name}
              onChange={(e) => {
                setcompany_name(e.target.value);
              }}
              InputLabelProps={{
                sx: {
                  color: "white",
                },
              }}
              sx={{
                borderRadius: "5px",
                width: matches ? "350px" : "280px",
                border: "1px solid white",
                bgcolor: "white",
                "&:hover": { border: "1px solid white" },
                ".MuiInputBase-input": { color: "#7B3AE2" },
                "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                  color: "#7B3AE2",
                  opacity: 1,
                },
              }}
            />
          </Grid>
        </Stack>

        <Stack
          direction={matches ? "row" : "column"}
          gap={2}
          sx={{ marginTop: "20px" }}
        >
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PhoneInput
              country={"sa"}
              enableSearch={true}
              value={phone}
              onChange={setPhone}
              inputStyle={{
                backgroundColor: "white",
                color: "#7B3AE2",
                fontSize: "13px",
                border: "0.1px solid rgba(196, 196, 196, 0.582)",
                borderRadius: "5px",
                height: "55px",
                marginTop: "-15px",
                direction: "ltr",
                width: matches ? "350px" : "280px",
              }}
              style={{
                width: matches ? "350px" : "280px",
              }}
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
              InputLabelProps={{
                sx: {
                  color: "white",
                },
              }}
              sx={{
                borderRadius: "5px",
                width: matches ? "350px" : "280px",
                bgcolor: "white",
                outline: "none",
                border: "1px solid white",
                "&:hover": { border: "1px solid white" },
                ".MuiInputBase-input": { color: "#7B3AE2" },
                "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
                  color: "#7B3AE2",
                  opacity: 1,
                },
              }}
            />
          </Grid>
        </Stack>
      </ThemeProvider>
      <Grid
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <textarea
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          name="message"
          placeholder="Message"
          value={message}
          className="custom-textarea"
          style={{
            resize: "none",
            overflow: "auto",
            height: "100px",
            width: matches ? "700px" : "265px",
            outline: "none",
          }}
        />
      </Grid>
      {alerting && (
        <Alert
          severity="error"
          sx={{ width: matches ? "350px" : "310px", marginTop: "20px" }}
        >
          One of the Feilds is empty
        </Alert>
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent:  matches?'':"center",
          alignItems: matches?'':"center",
        }}
      >
      <Button
        onClick={handlesend}
        sx={{
          color: "white",
          bgcolor: "#6CD6A9",
          "&:hover": { color: "white", bgcolor: "#6CD6A9" },
          padding: "5px 40px 5px 40px",
          mt: "30px",
          fontSize: "14px",
          mb: "30px",
        }}
      >
        Send
      </Button>
      </div>
    </div>
  );
}
