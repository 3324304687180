import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from './Media/SH.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from '@mui/material';
import {useMediaQuery} from '@mui/material'



function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width:1100px)');
  const show =  localStorage.getItem('show')
  const [pages,setPages] = React.useState([])
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = (page) => {
    const mainPagePath = '/';
    const isMainPage = window.location.pathname === mainPagePath;
    setAnchorElNav(null);
    if (!isMainPage) {
      navigate('/');
    } else {
      const sectionElement = document.getElementById(page);
      if (sectionElement) {
          const headerHeight = 90; 
          const sectionOffset = sectionElement.offsetTop - headerHeight;
          window.scrollTo({ top: sectionOffset, behavior: 'smooth' });
      } else {
        navigate('/');
        setTimeout(() => {
          const sectionElement = document.getElementById(page);
          sectionElement.scrollIntoView({ behavior: 'smooth' });
        }, 5000);
      }
    }
  };
  var head = 'zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas'
  React.useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}home`, {
      headers: {
        'X-Api-Password': head
      }
    }).then(res=>{
  if(res.data.data.testimonials.length > 0){
    setPages(['Our Service', 'Features', 'FAQs', 'Reviews' , 'Book A Demo' , 'Contact Us'])
  }
  else {
   setPages(['Our Service', 'Features', 'FAQs', 'Book A Demo' , 'Contact Us'])
  }
})
},[])
  return (
    <AppBar position="fixed" sx={{bgcolor:'white',borderBottom:'1px solid #707070'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              padding:'20px'
            }}
          >
            <img src={logo} style={{width:'100px',height:'50px',objectFit:'contain'}}/>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              onClose={()=>{setAnchorElNav(null)}}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              
              sx={{
                display: { xs: 'block', md: 'none' },'.MuiPaper-root':{
                  width:'100%',bgcolor:'white',border:'1px solid gray',mt:'5px'
                }
              }}
            >
                 <MenuItem>
               <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' }
            }}
          >
            <img src={logo} style={{width:'60px',height:'70px',objectFit:'contain'}}/>
          </Typography>

          </MenuItem>
              {pages.map((page) => (
                <MenuItem key={page} onClick={()=>{handleCloseNavMenu(page)}}>
                  <Typography textAlign="center" sx={{color:'#333134'}}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },flexWrap:'wrap'
          ,justifyContent:'center',alignItems:'center',gap:matches?'30px':''}}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={()=>{handleCloseNavMenu(page)}}
                sx={{ my: 2, color: '#333134', display: 'block', fontSize:'12px' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow:0 }}>
            <Tooltip>
            <Link style={{textDecoration: 'none'}} target="_blank" href='https://dashboard.swifthire.ai/' >
               <Button sx={{color:'white',bgcolor:'#6CD6A9','&:hover':{color:'white',bgcolor:'#6CD6A9'}
            ,padding:'5px 30px 5px 30px'}} 
            >PORTAL</Button>
            </Link>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;