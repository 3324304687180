import React from 'react'
import { Container, Stack, Typography, useMediaQuery } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay,Pagination } from 'swiper/modules'; 
import '../App.css'
import 'swiper/css/pagination';

export default function Intro({banners}) {
    const matches = useMediaQuery('(min-width:500px)');
  return (
    <div style={{marginTop:matches?'120px':'100px',marginBottom:matches?'':'80px'}}>
<Container maxWidth='xl' >
      <Swiper
      modules={[Autoplay,Pagination]} 
      loop={true}
      pagination={{ clickable: true }}
      autoplay={{
       delay: 5000,
       disableOnInteraction: false,
        }}
        slidesPerView={1}
        style={{paddingBottom:matches?'90px':'30px',paddingTop:matches?'40px':'20px'}}
        className="custom-swiper"
      >
        {banners.map((banners)=>
        <SwiperSlide style={{ display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
     <Stack direction='row' sx={{ display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
     {!matches && <Container maxWidth='xs'>
            <img src={`${process.env.REACT_APP_API_URL_IMAGE}${banners.image}`} 
            style={{height:'150px' ,width:'330px', objectFit:'contain'}}/>
            </Container>}
            <Container maxWidth='sm'sx={{p:matches?'':'50px'}}>
                <Typography sx={{color:'#7B3AE2',fontSize:'30px',fontWeight:'bold',textAlign:matches?'start':'center'}}>
                {banners.title}
                </Typography>
                <Typography sx={{color:'#6A6A6A',fontSize:'15px',textAlign:matches?'start':'center',mt:'10px'}}>
                {banners.description}
                </Typography>
            </Container>
            {matches && <Container maxWidth='xs'>
            <img src={`${process.env.REACT_APP_API_URL_IMAGE}${banners.image}`} style={{height:'250px' ,width:'500px', objectFit:'contain'}}/>
            </Container>}
        </Stack>
        </SwiperSlide>)}
        </Swiper>
        </Container>
    </div>
  )
}
