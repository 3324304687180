import { Button, Container, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import pic from '../Media/service.png'

export default function Services({service}) {
    const matches = useMediaQuery('(min-width:800px)');
    const [book, setBook] = useState('Book A Demo');
    var sectionOffset ;
    useEffect(()=>{
      const sectionElement = document.getElementById(book);
      const headerHeight = 120; 
      sectionOffset = sectionElement.offsetTop - headerHeight;
    },[])

   const handleScroll =() =>{
    window.scrollTo({ top: sectionOffset, behavior: 'smooth' });
   }
   
  return (
    <div style={{marginTop:matches?'100px':'0px'}} id='Our Service'>
      <Stack direction='row' >
       {matches && <img src={pic} style={{height:'550px',objectFit:'cover'}}/>}

        <Container maxWidth='sm' sx={{marginTop:matches?'100px':''}}>
        <Typography sx={{color:'#7B3AE2',fontSize:'30px',fontWeight:'bold',textAlign:matches?'start':'center'}}>
        Our Service
                </Typography>
                <Typography sx={{color:'#6A6A6A',fontSize:'15px',textAlign:matches?'start':'center',mt:'10px'}}>
                {service.description}
                </Typography>

<div style={{ display:'flex',flexWrap:'wrap',justifyContent:matches?'start':'center',alignItems:'center'}}>
                <Button  sx={{color:'white',bgcolor:'#6CD6A9','&:hover':{color:'white',bgcolor:'#6CD6A9'}
            ,padding:'5px 30px 5px 30px', mt:'30px', fontSize:'14px'}} onClick={handleScroll}>BOOK A DEMO</Button></div>
        </Container>
      </Stack>
    </div>
  )
}
