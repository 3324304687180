import { Button, Stack, TextField, useMediaQuery } from '@mui/material';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { ReactMediaRecorder } from 'react-media-recorder-2';
import { useLocation, useNavigate } from 'react-router-dom';

export default function VideoRecorder() {
  const [videoBlob, setVideoBlob] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [rec, setRec] = useState(false);
  const windowHeight = useRef(window.innerHeight);
  const videoRef = useRef(null);

  const handleStopRecording = (mediaBlobUrl) => {
    setRec(true);
    setVideoBlob(mediaBlobUrl);
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const matches = useMediaQuery('(min-width:600px)');

  const starting = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setMediaStream(stream);
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });

    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = mediaStream;
    }
  }, [mediaStream]);

  const handleSaveVideo = async () => {
    // if (videoBlob) {
    //   const file = new File([videoBlob], 'recorded_video.mp4', { type: 'video/mp4' });
    //   const formData = new FormData();
    //   formData.append('answer_video', file);
    //   formData.append('question_id', id);
    //   formData.append('candidate_id', data);
    //   var head = 'zdmZXd%cK$!Qq8FQ2psdXpO5@EP$4aMJujas'
    //   axios
    //     .post(`${process.env.REACT_APP_API_URL}answer`, formData, {
    //       headers: {
    //         'X-Api-Password': head,
    //       },
    //     })
    //     .then((res) => {
    //       navigate('/questions', { state: { data: localStorage.getItem('id'), lang: localStorage.getItem('lang') } });
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }
    //  else {
    //   alert('you have to record video');
    // }
  };

  return (
    <div style={{ marginTop: '200px', minHeight: windowHeight.current }}>
      <ReactMediaRecorder
        video
        render={({ startRecording, stopRecording, pauseRecording, resumeRecording, mediaBlobUrl }) => (
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            <Stack direction={matches ? 'row' : 'column'} gap={5}>
              <Stack>
                <Button onClick={() => { startRecording(); starting(); setRec(false); }} sx={{ color: 'green' }}>Start Recording</Button>
                <Button onClick={() => { stopRecording(); handleStopRecording(mediaBlobUrl); }} sx={{ color: 'red' }}>Stop Recording</Button>
                <Button onClick={pauseRecording}>Pause Recording</Button>
                <Button onClick={resumeRecording}>Resume Recording</Button>
                <Button onClick={() => { window.location.reload(true); }}>Restart Recording</Button>
                {rec && (
                  <Button onClick={handleSaveVideo} sx={{
                    color: 'white',
                    bgcolor: '#0D969B',
                    '&:hover': { color: 'white', bgcolor: '#0D969B' },
                    padding: '5px 20px 5px 20px',
                    mb: '20px',borderRadius:'20px',mt:'10px'
                  }}>
                    Submit
                  </Button>
                )}
              </Stack>
              {rec && <video src={mediaBlobUrl} controls style={{ width: '100%', maxWidth: '500px' }} />}
              {!rec && <video ref={videoRef} style={{ width: '100%', maxWidth: '500px' }} autoPlay />}
            </Stack>
          </div>
        )}
        onStop={handleStopRecording}
      />
    </div>
  );
}