import { HelmetProvider } from 'react-helmet-async';
import { HashRouter, Route, Routes } from 'react-router-dom';
import MainPage from './Pages/MainPage';
import Navbar from './Navbar';
import Footer from './Footer';
import {  createTheme , ThemeProvider} from '@mui/material';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Terms from './Pages/Terms';
import Refund from './Pages/Refund';
import VideoRecorder from './Component/VideoRecorder';
import WelcomePage from './Pages/WelcomePage';
import Questions from './Pages/Questions';
import EndPage from './Pages/EndPage';

function App() {
  const theme = createTheme({
    typography: {
      fontFamily:'Montserrat, sans-serif'
    },
    Button:{
      fontFamily:'Montserrat, sans-serif'
    }
  });
  return (
    <div>
      <HelmetProvider>
        <HashRouter>
        <ThemeProvider theme={theme}>
        <Navbar />
        <Routes>
          <Route path="/" element= {<MainPage />}/>
          <Route path="/privacypolicy" element= {<PrivacyPolicy />}/>
          <Route path="/terms" element= {<Terms />}/>
          <Route path="/refundpolicy" element= {<Refund />}/>
          <Route path="/welcome" element= {<WelcomePage />}/>
          <Route path="/questions" element= {<Questions />}/>
          <Route path="/video" element= {<VideoRecorder />}/>
          <Route path="/done" element= {<EndPage />}/>
          </Routes>
          <Footer />
          </ThemeProvider>
        </HashRouter>
        </HelmetProvider>
    </div>
  );
}

export default App;
